import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "@mantine/core"; // Assuming you are using Mantine for Modal
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { getTypeRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import OnboardingProcess from "../Common/OnboardingProcess";
import axios from "axios";

import Tour from "reactour";

const stepStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

export const PrabaltaServicesDashboard = () => {
  const [subdomainTitle, setSubdomainTitle] = useState(
    "Build your own website"
  );
  const [subdomainLink, setSubdomainLink] = useState("/createDomain");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const [nextModule, setNextModule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isTourOpen, setIsTourOpen] = useState(false);

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [onboardingOpenFlag, setOnboardingOpenFlag] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const partnerId = partnerData.partnerId;

  const [steps, setSteps] = useState([
    {
      selector: "#service-donor",
      content: (
        <div className="step-content">
          <span className="emoji-large">🎉</span>
          <div>
            This is your first service to manage your donor records. Learn more
            about it here.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-social",
      content: (
        <div className="step-content">
          <span className="emoji-large">📱</span>
          <div>
            This is the Social integration section where you can schedule posts
            or reels to share on social media.
          </div>
          <div>It's quick and easy!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-domain",
      content: (
        <div className="step-content">
          <span className="emoji-large">🌐</span>
          <div>
            This is the section where you can build your own domain for your
            brand.
          </div>
          <div>Make it unique!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-email",
      content: (
        <div className="step-content">
          <span className="emoji-large">✉️</span>
          <div>
            This is the email service section for managing your campaigns and
            communications.
          </div>
          <div>Reach your audience effectively!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-analytics",
      content: (
        <div className="step-content">
          <span className="emoji-large">📊</span>
          <div>
            This is the analytics and reporting section to monitor your
            performance.
          </div>
          <div>Make informed decisions!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
    {
      selector: "#service-payment",
      content: (
        <div className="step-content">
          <span className="emoji-large">💳</span>
          <div>
            This is the payment section where you can manage all transactions.
          </div>
          <div>Secure and simple!</div>
        </div>
      ),
      style: { ...stepStyle, animation: "fadeIn 0.5s ease-in-out" },
    },
  ]);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const response = await axios.get(
          `${TQ_URL}/user-Onboarding-status/${partnerId}`
        );
        console.log(response.data.onBoardingStatus);

        if (response.data.onBoardingStatus === false) {
          setIsOnboardingOpen(true); // Open the modal if the onboarding status is false

          await updateOnboardingStatusToTrue();
        } else {
          setIsTourOpen(true);
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

    const updateOnboardingStatusToTrue = async () => {
      try {
        await axios.put(`${TQ_URL}/update-onboarding-status/${partnerId}`, {
          onBoardingStatus: true,
        });
        console.log("Onboarding status updated to true");
      } catch (error) {
        console.error("Error updating onboarding status:", error);
      }
    };

    fetchOnboardingStatus();
  }, []);

  useEffect(() => {
    const fetchNextModule = async () => {
      try {
        const response = await axios.get(`${TQ_URL}/next/${partnerId}`);

        console.log(response);

        console.log(response.data);
        console.log(response.data.modules.moduleId);
        if (response.status === 200) {
          const moduleId = response.data.modules.moduleId;
          console.log("Fetched module ID:", moduleId);
          if (response.data && response.data.modules !== undefined) {
            setCurrentStep(moduleId - 1); // Assuming moduleId corresponds to step index + 1
            //    setIsTourOpen(true);
          }
        } else {
          setNextModule(null); // No content (e.g., tour completed)
        }
      } catch (err) {
        setError("Error fetching the next module");
      }
    };

    fetchNextModule();
  }, []);

  useEffect(() => {
    console.log("Updated currentStep value: ", currentStep);
  }, [currentStep]);

  useEffect(() => {
    fetchDomainStatus();
  }, []);
  const handleOnboardingModalClose = () => {
    console.log("close function called");
    setIsOnboardingOpen(false);
    setIsTourOpen(true);
  };

  const fetchDomainStatus = async () => {
    try {
      const partnerId = partnerData.partnerId; // Replace with your partner ID or fetch it from context or props.
      const response = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/check/${partnerId}`
      );

      console.log(response);
      if (response === "Domain exists for the given partner ID.") {
        setSubdomainTitle("Manage your subdomain");
        setSubdomainLink("/money-details"); // Update link if domain exists
      }
    } catch (error) {
      console.error("Error fetching domain status:", error);
      // Handle error appropriately
    }
  };

  const handleAnalyticsClick = async (e) => {
    e.preventDefault();

    try {
      const partnerId = 1; // Replace with your partner ID or fetch it from context or props.
      const response = await getTypeRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/check/${partnerId}`
      );

      if (response === "Domain exists for the given partner ID.") {
        navigate("/analyticsDashboard");
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error checking domain status:", error);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/createDomain");
  };

  const handleSkip = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <DonorManagementTop />
  
      <main className="flex h-full my-10 justify-center">
        <div className="container px-5 md:px-10 lg:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-10">
          {/* Service Cards */}
          {[
            {
              id: "service-donor",
              title: "Manage your donor base",
              link: "/donorlist",
              image: "assets/images/Office_management-pana.png",
            },
            {
              id: "service-social",
              title: "Market on social media",
              link: "/schedulePost",
              image: "assets/images/Social_media-bro.png",
            },
            {
              id: "service-domain",
              title: subdomainTitle, // Dynamic Title
              link: subdomainLink,   // Dynamic Link
              image: "assets/images/Website_designer-pana.png",
            },
            {
              id: "service-email",
              title: "Run your campaigns",
              link: "/mailingList",
              image: "assets/images/Email_campaign-amico.png",
            },
            {
              id: "service-analytics",
              title: "Get Analytics and Reporting",
              link: "#",
              image: "assets/images/Dark_analytics-pana.png",
              onClick: handleAnalyticsClick,
            },
            {
              id: "service-payment",
              title: "Utilize multiple payment services",
              link: "/paymentMethod",
              image: "assets/images/Plain_credit_card_pana.png",
            },
          ].map((service) => (
            <div
              key={service.id}
              id={service.id}
              className="group relative rounded-lg bg-white shadow-md hover:shadow-2xl overflow-hidden transform transition-transform duration-300 hover:scale-105"
            >
              <Link
                to={service.link}
                onClick={service.onClick || undefined}
                className="block"
              >
                {/* Image Section */}
                <div className="relative w-full h-52 overflow-hidden">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-full h-full object-contain transition-transform duration-500 group-hover:scale-110"
                  />
                </div>
  
                {/* Title Section */}
                <div className="text-center py-5 px-3">
                  <h2 className="text-xl font-bold text-gray-800 group-hover:text-[#0E398F] transition-colors duration-300">
                    {service.title}
                  </h2>
                </div>
  
                {/* Hover Border Effect */}
                <div className="absolute inset-0 border-2 border-transparent rounded-lg group-hover:border-[#0E398F] transition-all duration-300"></div>
              </Link>
            </div>
          ))}
        </div>
      </main>
  
      {/* Modal for Creating Subdomain */}
      <Modal
        opened={isModalOpen}
        onClose={handleModalClose}
        centered
        size="lg"
        overlayOpacity={0.8}
        overlayBlur={5}
        transition="fade"
        transitionDuration={500}
        withCloseButton={false}
        closeOnClickOutside={false}
        classNames={{
          modal: "bg-gradient-to-r from-yellow-400 to-orange-500 p-8 rounded-lg",
        }}
        styles={{
          modal: {
            height: "450px",
            paddingTop: "20px",
          },
        }}
      >
        <div className="flex flex-col items-center justify-center text-center h-full">
          <div className="text-6xl mb-4 animate-bounce">🤔</div>
          <h2 className="text-3xl font-bold mb-4 text-black drop-shadow-lg">
            Consider Creating a Subdomain First
          </h2>
          <p className="text-lg mb-6 text-black drop-shadow-lg">
            To access this module, you need to create a subdomain. Please continue
            to create your subdomain.
          </p>
          <div className="flex space-x-4">
            <Button
              className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
              onClick={handleModalClose}
            >
              Continue
            </Button>
            <Button
              className="bg-white text-blue-800 hover:bg-gray-200 px-6 py-2 rounded-full"
              onClick={handleSkip}
            >
              Skip
            </Button>
          </div>
        </div>
      </Modal>
  
      {/* Onboarding Process */}
      {isOnboardingOpen && (
        <OnboardingProcess
          isOpen={isOnboardingOpen}
          closeModal={handleOnboardingModalClose}
        />
      )}
  
      {/* Tour Component */}
      {isTourOpen && currentStep !== null && isLargeScreen && (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          rounded={5}
          startAt={currentStep}
          stepInteraction={true}
          maskClassName="mask"
          accentColor="#269faf"
          onRequestClose={() => setIsTourOpen(false)}
          nextButton={<button className="tour-button">Next ➡️</button>}
          prevButton={<button className="tour-button">⬅️ Back</button>}
          showSkipButton={true}
          lastStepNextButton={
            <button
              className="tour-button"
              onClick={() => setIsTourOpen(false)}
            >
              Finish 🎉
            </button>
          }
        />
      )}
    </div>
  );
  
};

export default PrabaltaServicesDashboard;
