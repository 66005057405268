//Local Settings:

// export const TQ_URL = "http://localhost:8080";
// export const TQ_FRONTEND_URL = "http://localhost:3000/oauth2/redirect";
// export const TQ_URL_USPROJECT = "http://localhost:8081";


// export const TQ_URL = "http://192.168.137.227:8080";
// export const TQ_FRONTEND_URL = "http://192.168.137.227:3000/oauth2/redirect";
// export const TQ_URL_USPROJECT = "http://192.168.137.227:8081";

// ****************************************************************************************
//Live Settings :

// export const TQ_URL_USPROJECT = "https://prabalta.org:8087/usproject";
// export const TQ_URL = "https://prabalta.org:8087/prabaltatwo";
// export const TQ_FRONTEND_URL = "https://prabalta.org/oauth2/redirect";
// *****************************************************************************************
// UAT Settings.

export const TQ_FRONTEND_URL =
  "https://prabaltauat.prabalta.org/oauth2/redirect";
export const TQ_URL = "https://prabalta.org:8087/prabaltatwouat";
export const TQ_URL_USPROJECT = "https://prabalta.org:8087/usprojectuat";

export const isSocial = window.location.hostname === "prabalta.org";

console.log("API URL:", TQ_URL);

export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI = "https://prabalta.org/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  TQ_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  TQ_URL + "/oauth2/authorize/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  TQ_URL + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;
