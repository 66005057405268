import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { getRequestWithTokenWithData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import fetchConfig from "../../config"; // Import the fetchConfig function

const stripePromise = loadStripe(
  "pk_test_51PAyezLe3p6lyB5zxgX9dWr1cKnaCQaLeMuszH89MHOpdexYhLjNEYkCN1agqwFHB5hNisslZhEiA29wcQVqzctQ00SttfXyvP"
);

// const stripePromise = loadStripe(
//   "pk_live_51PAyezLe3p6lyB5zhlWFUpI7xyQm6KbSaH3qKMeZHcB9FUt9TiETLKyuKFwAAL59S4i6fXYXPN9Ux007WoTsvVJT00foz3nV3e"
// );

const StripeRecurringPayment = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [config, setConfig] = useState(null); // State to store config data

  const { state } = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  console.log(state);

  const donatedAmt = state?.donatedAmt;
  const frequency = state?.frequency;
  const fundraiserId = state?.fundraiserId;
  const currencyCode = state?.currencyCode;
  const status = state?.status;

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const userId = userData.userId;
  const sessionCreatedRef = useRef(false);

  // Function to create checkout session
  const createCheckoutSession = async (partnerId) => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL_USPROJECT}/create-reccuring-session`,
        {
          donatedAmt: donatedAmt,
          freqId: frequency,
          fundraiserId: fundraiserId,
          currencyCode: currencyCode,
          status: status,
          donorId: userId,
          autoPay: false,
          partnerId: partnerId, // Add partnerId to the request data
        }
      );
      setClientSecret(response.clientSecret);
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast.error("Something went wrong");
      setTimeout(() => {
        navigate("/donate");
      }, 1500);
    }
  };

  // Fetch config on component mount and initiate checkout session
  useEffect(() => {
    const fetchAndSetConfig = async () => {
      const configData = await fetchConfig();
      setConfig(configData);
    };

    fetchAndSetConfig();
  }, []);

  // Create checkout session once config is loaded
  useEffect(() => {
    if (config && !clientSecret && !sessionCreatedRef.current) {
      createCheckoutSession(config.partnerId);
      sessionCreatedRef.current = true;
    }
  }, [config, clientSecret]); // Add config and clientSecret as dependencies

  const options = { clientSecret };

  const checkSessionStatus = async () => {
    try {
      const response = await axios.post(`${TQ_URL_USPROJECT}/session_status`, {
        session_id: clientSecret,
      });
      // Handle the session status response here
      console.log("Session Status:", response.data);
    } catch (error) {
      console.error("Error checking session status:", error);
    }
  };

  return (
    <>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <ToastContainer theme="colored" />;
    </>
  );
};

export default StripeRecurringPayment;
