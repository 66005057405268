import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getRequestWithTokenWithoutData,
  getRequestWithOutToken,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .positive("Amount must be a positive number")
    .required("Amount is required"),
  frequency: yup.string().required("Frequency is required"),
});

const DonateNow = ({ onComplete }) => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(1);
  const [fundraiserList, setFundriaseList] = useState([]);
  const [selectedCauseType, setSelectedCauseType] = useState([]);
  const [fundraiserShare, setFundraiserShare] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [frequencies, setFrequencies] = useState([]);
  const [showFees, setShowFees] = useState(false);
  const isMounted = useRef(true);
  const [startIndex, setStartIndex] = useState(0);
  const [visibleCauses, setVisibleCauses] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [errorCauseType, setErrorCauseType] = useState("");
  const [config, setConfig] = useState({});
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [animationClass, setAnimationClass] = useState("modal-swipe-right");
  const isUserLoggedIn = () => {
    return userData !== null;
  };

  const { state } = useLocation();
  console.log(state);

  const itemsPerPage = 6;

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchConfig();
    logLocalData();
  }, []);

  useEffect(() => {
    if (isMounted.current && config.partnerId) {
      getFundraiserList();
      getFrequencyList();
      isMounted.current = false;
    }
  }, [config]);

  useEffect(() => {
    // Check if there is a pre-filled amount when the component mounts
    const amountValue = getValues("amount"); // Use getValues from useForm
    if (amountValue != null) {
      // Check if amountValue is not null or undefined
      // Convert amountValue to string for safe manipulation
      const amountString = String(amountValue);
      //  const initialValue = parseInt(amountString.replace(/\D/g, ""), 10);
      handleAmountChange({ target: { value: amountString } }, (value) => {
        setValue("amount", value); // Update the control value
      });
    }
  }, [getValues, setValue]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [counter]);

  useEffect(() => {
    if (state) {
      if (state.causeType) {
        const selectedCauses = fundraiserList.filter((fundraiser) =>
          state.causeType.includes(fundraiser.title)
        );
        console.log("------------->", selectedCauses);

        const selectedCauseIds = selectedCauses.map((fundraiser) => fundraiser.fundraiserId);
        setSelectedValues(selectedCauses);
        setValue("causeType", selectedCauseIds);
        setSelectedCauseType(selectedCauseIds);

        setErrorCauseType(selectedCauses ? "" : "Please select a Fundraiser type");
      }

      // Donated Amount
      if (state.donatedAmt) {
        setValue("amount", state.donatedAmt);
      }

      // Frequency
      if (state.freqId) {
        setValue("frequency", state.freqId);
      }
    }
  }, [state, fundraiserList, setValue]);

  const logLocalData = () => {
    const localStorageData = localStorage.getItem("userData");
    console.log("Local Storage Data:", JSON.parse(localStorageData));

    setUserData(JSON.parse(localStorageData));
  };

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  // const getCauseList = async () => {
  //   const partnerId = config.partnerId;
  //   if (!partnerId) {
  //     throw new Error("Partner ID is not defined");
  //   }
  //   try {
  //     // const response = await getRequestWithTokenWithoutData(
  //     //   `${TQ_URL_USPROJECT}/getCauseList?partnerId=${partnerId}`
  //     // );
  //     const response = await getRequestWithOutToken(
  //       `${TQ_URL_USPROJECT}/getCauseList?partnerId=${partnerId}`
  //     );
  //     const visibleCausess = response.data.slice(
  //       startIndex,
  //       startIndex + itemsPerPage
  //     );
  //     setCauseList(response.data);
  //     setVisibleCauses(visibleCausess);
  //   } catch (error) {
  //     console.log("error in fetching the cause list");
  //     //handleError(error, "Error fetching cause list data.");
  //   }
  // };


  const getFundraiserList = async () => {
    const partnerId = config.partnerId;
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    try {
      // const response = await getRequestWithTokenWithoutData(
      //   `${TQ_URL_USPROJECT}/getCauseList?partnerId=${partnerId}`
      // );
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.get(
        `${TQ_URL_USPROJECT}/getTopFundraiserList`,
        {
          params: {
            partnerId: partnerId,
            timeZone: browserTimeZone, // Add the timezone parameter
          },
        }
      );
      // const visibleCausess = response.data.slice(
      //   startIndex,
      //   startIndex + itemsPerPage
      // );
      console.log("------------->", response.data);

      setFundriaseList(response.data);
      // setVisibleCauses(visibleCausess);
    } catch (error) {
      console.log("error in fetching the cause list");
      //handleError(error, "Error fetching cause list data.");
    }
  };

  const getFrequencyList = async () => {
    try {
      const response = await getRequestWithOutToken(
        `${TQ_URL_USPROJECT}/getFrequencyList`
      );
      console.log("Fetched Frequencies:", response.data); // Log the response data
      const frequencyOrder = [
        "One-Time",
        "Daily",
        "Weekly",
        "Monthly",
        "Yearly",
      ];
      const sortedFrequencies = response.data.sort(
        (a, b) =>
          frequencyOrder.indexOf(a.frequencyName) -
          frequencyOrder.indexOf(b.frequencyName)
      );
      setFrequencies(sortedFrequencies);
    } catch (error) {
      console.error("Error fetching frequency list:", error);
    }
  };

  const getTruncatedCauses = (selected, maxLength) => {
    const selectedCause = selected.map((fund) => fund.title).join(", ");

    if (selectedCause.length > maxLength) {
      return selectedCause.slice(0, maxLength).trim() + "...";
    }

    return selectedCause;
  };

  const handleError = (error, defaultMessage) => {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error(defaultMessage);
    }
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const uniqueValues = [...new Set(value)];
  //   setSelectedValues(uniqueValues);
  //   setErrorCauseType(uniqueValues ? "" : "Please select a fundraiser title");
  //   const selectedCauseIds = uniqueValues.map((item) => item.fundraiserId);
  //   setSelectedCauseType(selectedCauseIds);
  // };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Directly store the single selected value
    setSelectedValues(value);
    setErrorCauseType(value ? "" : "Please select a fundraiser title");

    // Extract the selected fundraiser ID and set it
    const selectedCauseId = value.fundraiserId;
    setSelectedCauseType(selectedCauseId);
  };


  // const handlePrevClick = () => {
  //   setStartIndex(Math.max(0, startIndex - itemsPerPage));
  //   setVisibleCauses(causeList.slice(startIndex, startIndex + itemsPerPage));
  // };

  // const handleNextClick = () => {
  //   setStartIndex(
  //     Math.min(causeList.length - itemsPerPage, startIndex + itemsPerPage)
  //   );
  //   setVisibleCauses(causeList.slice(startIndex, startIndex + itemsPerPage));
  // };

  const formatCurrency = (value) => {
    if (value === null || value === undefined || value === "") return "";
    const amount = parseFloat(value);
    if (isNaN(amount)) return "";
    return `$${amount.toLocaleString("en-US", { maximumFractionDigits: 2 })}`;
  };

  const handleAmountChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      setFundraiserShare(0);
      setPlatformFee(0);
      return;
    }
    value = parseInt(value, 10);

    // Format the value
    const formattedAmount = formatCurrency(value);
    e.target.value = formattedAmount;

    // Set the cursor to the end of input after formatting
    // setTimeout(() => {
    //   e.target.setSelectionRange(
    //     formattedAmount.length,
    //     formattedAmount.length
    //   );
    // }, 0);

    // Ensure onChange is called with the parsed value
    onChange(value);

    // Calculate platform fee (4%) and fundraiser amount
    const platformFee = Number((value * 0.04).toFixed(2));
    const fundraiserAmount = Number((value - platformFee).toFixed(2));

    // Update fundraiser share and platform fee
    setFundraiserShare(fundraiserAmount);
    setPlatformFee(platformFee);
  };

  const onSubmitHandler = async (data, event) => {
    if (selectedCauseType.length <= 0) {
      setErrorCauseType("Please select fundraiser title");
      return;
    }
    setErrorCauseType("");
    event.preventDefault();
    const request = {
      donatedAmt: parseFloat(data.amount),
      frequency: data.frequency,
      fundraiserId: selectedCauseType,
      freqId: data.frequency,
      autoPay: false,
      currencyCode: "USD",
      status: 1,
    };

    console.log(request);

    if (isUserLoggedIn() && localStorage.getItem("accessToken") !== null) {
      navigate("/stripe-donation", { state: request });
    } else {
      localStorage.setItem("donationData", JSON.stringify(request));
      setShowModal(true);

      //  // navigate("/ngo-login");
      //   navigate("/ngo-login", {
      //     state: { redirectAfterLogin: "/stripe-donation" },
      //   });
    }
  };

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  const handleLoginRedirect = () => {
    setTimeout(() => {
      setShowModal(false);
      navigate("/ngo-login", {
        state: { redirectAfterLogin: "/donate" },
      });
    }, 700); // Match the duration of your modal's transition
  };

  const handleCloseModal = () => {
    // Animate the modal closing
    setTimeout(() => setShowModal(false), 700); // Close modal after animation
  };

  const handleDonateNowClick = () => {
    navigate(localStorage.getItem("accessToken") ? "/donate" : "/login");
  };

  return (
    <div>
      <Header />
      <div className="fundraiser-banner-section  ">
        <div className=" mx-auto md:flex items-center justify-between">
          <div className="lg:w-2/5 md-lg:w-full md:w-full md:p-8 md-lg:p-8 lg:p-0 ">
            <div>
              <img
                src="/assets/images/recurringDonation.jpg"
                className="fundraiser-slider-box rounded-md"
              />
            </div>
          </div>

          <div className="lg:w-1/2 md:w-full md-lg:w-full pr-6 md:py-20 p-4 ">
            <h2 className="text-2xl font-semibold mb-4 w-2/3">
              Recurring Donation Details
            </h2>
            <h1 className="banner-text-2 leading-none w-full ">
              Sustain Our <br /> Cause,
              <span className="yellow-texture-bg "> Recurringly</span>
            </h1>




            <h2 className="text-2xl font-semibold mb-4 w-1/2">{"  "}</h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2">&nbsp;</h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2 text-blue-800">
              &nbsp;
            </h2>
          </div>
        </div>
      </div>

     

      <div className="mx-6 lg:py-20 md:py-4 md-lg:py-4 md:flex  items-center justify-evenly">
        <div className="2xl:w-1/4 imageRecurring  md:py-0 w-full md-lg:hidden lg:block hidden">
          <img
            src="/assets/images/recurring2Crop.jpg"
            className="w-full rounded-lg"
          />
        </div>

        <div>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="fundraiser-for-other-form px-6 mobileRecurring md:py-12 formStyleFormini md:px-20 md:w-fit sd:w-full bg-white px-4 py-6 my-4 mx-2"
          >
            <div className="mb-6 md:flex inputWidth">
              <label>Fundraiser title*</label>
              <div className="w-full ">
                <FormControl
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errorCauseType && "border-red-500"
                    }`}
                >
                  <InputLabel
                    className="text-black-900 font-semibold text-sm"
                    id="demo-multiple-checkbox-label"
                    style={{ zIndex: showModal ? -1 : 1 }}
                  >
                    Select Fundraiser title
                  </InputLabel>
                  {/* <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    
                    value={selectedValues}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Causes" />}
                    // renderValue={(selected) =>
                    //   selectedValues.map((x) => x.causeName).join(", ")
                    // }
                    renderValue={(selected) => getTruncatedCauses(selected, 60)}
                    MenuProps={MenuProps}
                  >
                    {fundraiserList.map((variant) => (
                      <MenuItem
                        key={variant.fundraiserId}
                        value={variant}
                        style={{
                          padding: "5px",
                          fontSize: "16px",
                          margin: "2px",
                        }}
                      >
                        <Checkbox
                          checked={selectedValues.includes(variant)}
                          style={{
                            fontSize: "16px",
                            margin: "5px",
                          }}
                        />
                        <ListItemText primary={variant.title} />
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    labelId="demo-single-select-label"
                    id="demo-single-select"
                    value={selectedValues} // single value instead of array
                    onChange={handleChange} // Updated logic for single selection
                    input={<OutlinedInput label="Select Causes" />}
                    MenuProps={MenuProps}
                  >
                    {fundraiserList.map((variant) => (
                      <MenuItem
                        key={variant.fundraiserId}
                        value={variant} // single value
                        style={{
                          padding: "5px",
                          fontSize: "16px",
                          margin: "2px",
                        }}
                      >
                        <ListItemText primary={variant.title} />
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
                {errorCauseType && (
                  <p style={{ color: "red" }}>{errorCauseType}</p>
                )}
              </div>
            </div>

            <div className="mb-6 relative md:flex inputWidth">
              <label>Amount*</label>
              <div className="w-full relative">
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      {/* <input
                        value={value ? formatCurrency(value) : ""} // Format only for display
                        onChange={(e) => handleAmountChange(e, onChange)}
                        ref={ref}
                        placeholder="  Enter Amount*"
                        type="text"
                        min="0"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                          errors.amount ? "border-red-500" : ""
                        }`}
                      /> */}

                      <input
                        value={value ? formatCurrency(value) : ""} // Format only for display
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Allow typing freely
                          e.target.value = `$${numericValue}`; // Update displayed value with dollar sign
                          handleAmountChange(e, onChange); // Call your existing handler
                        }}
                        onBlur={(e) => {
                          // Enforce minimum $10 on blur
                          let numericValue = parseInt(
                            e.target.value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (isNaN(numericValue) || numericValue < 10) {
                            numericValue = 10; // Set to minimum
                            toast.error("Minimum Donation is $10"); // Show error message
                          }
                          e.target.value = `$${numericValue}`; // Update displayed value
                          handleAmountChange(e, onChange); // Call your existing handler
                        }}
                        ref={ref}
                        placeholder="Enter Amount*"
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.amount ? "border-red-500" : ""
                          }`}
                      />
                    </>
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.amount?.message}
                </p>

                <div
                  className={`transition-all duration-500 ease-in-out ${fundraiserShare > 0 && platformFee > 0
                      ? "opacity-100 max-h-48"
                      : "opacity-0 max-h-0"
                    } overflow-hidden`}
                >
                  <div className="mt-4 w-full bg-blue-50 border border-blue-200 rounded-md p-4 flex flex-col space-y-4">
                    <div className="flex items-center space-x-2 w-full">
                      <span role="img" aria-label="Fundraiser">
                        💰
                      </span>
                      <span className="text-blue-900 font-semibold">
                        Fundraiser's Share:{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(fundraiserShare)}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2 w-full">
                      <span role="img" aria-label="Platform Fee">
                        📊
                      </span>
                      <span className="text-blue-900 font-semibold">
                        Platform Fee (4% of Amount):{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(platformFee)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mb-6 md:flex inputWidth">
              <label className="block">Select Frequency*</label>
              <div className="block w-full md:flex md:space-x-6">
                {frequencies.map((frequency) => {
                  // console.log("Rendering Frequency:", frequency); // Log each frequency
                  return (
                    <div key={frequency.freqId}>
                      <label>
                        <Controller
                          name="frequency"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="radio"
                                value={frequency.freqId}
                                id={frequency.freqId}
                                checked={
                                  field.value === frequency.freqId.toString()
                                }
                                className="me-2"
                              />
                              <label htmlFor={frequency.freqId}>
                                {frequency.frequencyName}
                              </label>
                            </>
                          )}
                        />
                      </label>
                    </div>
                  );
                })}
                <p className="text-red-500 text-sm">
                  {errors.frequency?.message}
                </p>
              </div>
            </div> */}

            {/* <div className="mb-6 inputWidth">
              <label className="block">Select Frequency*</label>
              <div className="block w-full flex flex-wrap md:space-x-6">
                {frequencies.map((frequency) => {
                  return (
                    <div
                      key={frequency.freqId}
                      className="w-full md:w-auto md:flex-shrink-0 md:mr-4 mb-2 md:mb-0"
                    >
                      <label>
                        <Controller
                          name="frequency"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="radio"
                                value={frequency.freqId}
                                id={frequency.freqId}
                                checked={
                                  field.value === frequency.freqId.toString()
                                }
                                className="mr-2"
                              />
                              <label htmlFor={frequency.freqId}>
                                {frequency.frequencyName}
                              </label>
                            </>
                          )}
                        />
                      </label>
                    </div>
                  );
                })}
                <p className="text-red-500 text-sm">
                  {errors.frequency?.message}
                </p>
              </div>
            </div> */}

            {/* <div className="mb-6 inputWidth">
              <div className="flex flex-col md:flex-row items-center md:space-x-4">
                <label className="block md:mb-0 md:w-auto">
                  Select Frequency*
                </label>
                <div className="block w-full flex flex-wrap justify-start md:space-x-6">
                  {frequencies.map((frequency) => {
                    return (
                      <div
                        key={frequency.freqId}
                        className="w-full md:w-auto md:flex-shrink-0 md:mr-4 mb-2 md:mb-0"
                      >
                        <label>
                          <Controller
                            name="frequency"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  type="radio"
                                  value={frequency.freqId}
                                  id={frequency.freqId}
                                  checked={
                                    field.value === frequency.freqId.toString()
                                  }
                                  className="mr-2"
                                />
                                <label htmlFor={frequency.freqId}>
                                  {frequency.frequencyName}
                                </label>
                              </>
                            )}
                          />
                        </label>
                      </div>
                    );
                  })}
                  <p className="text-red-500 text-sm">
                    {errors.frequency?.message}
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div className="mb-6 inputWidth">
              <div className="flex flex-col md:flex-row items-start md:space-x-4">
                <label className="block md:mb-0 md:w-auto">
                  Select Frequency*
                </label>
                <div className="block w-full grid grid-cols-1 md:grid-cols-3 lg:flex lg:space-x-6 gap-y-4">
                  {frequencies.map((frequency) => {
                    return (
                      <div
                        key={frequency.freqId}
                        className="w-full md:w-auto md:flex-shrink-0"
                      >
                        <label>
                          <Controller
                            name="frequency"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  type="radio"
                                  value={frequency.freqId}
                                  id={frequency.freqId}
                                  checked={
                                    field.value === frequency.freqId.toString()
                                  }
                                  className="mr-2"
                                />
                                <label htmlFor={frequency.freqId}>
                                  {frequency.frequencyName}
                                </label>
                              </>
                            )}
                          />
                        </label>
                      </div>
                    );
                  })}
                </div>
                <p className="text-red-500 text-sm mt-2">
                  {errors.frequency?.message}
                </p>
              </div>
            </div> */}

            <div className="mb-6 mt-8 inputWidth">
              <div className="flex flex-col md:flex-row items-start md:space-x-4">
                <label className="block md:mb-0 md:w-auto">
                  Select Frequency*
                </label>
                <div className="block w-full">
                  <div className="grid grid-cols-1 gap-y-4 md:grid-cols-3 lg:flex lg:space-x-6">
                    {frequencies.map((frequency) => (
                      <div
                        key={frequency.freqId}
                        className="w-full md:w-auto md:flex-shrink-0"
                      >
                        <label>
                          <Controller
                            name="frequency"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  type="radio"
                                  value={frequency.freqId}
                                  id={frequency.freqId}
                                  checked={
                                    field.value === frequency.freqId.toString()
                                  }
                                  className="mr-2"
                                />
                                <label htmlFor={frequency.freqId}>
                                  {frequency.frequencyName}
                                </label>
                              </>
                            )}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                  {/* Error message in a separate row, below the radio buttons */}
                  <p className="text-red-500 text-sm mt-2">
                    {errors.frequency?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-6 md:flex justify-between">
              <div></div>

              <div className="md:w-4/6">
                <div className="flex w-full md:justify-between justify-center items-center">
                  <button
                    type="submit"
                    className="px-6  text-white p-2 rounded-sm btn-style-1 md:mt-12 mt-6 !font-semibold"
                  >
                    Donate Now
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            contentLabel="Login Required"
            shouldCloseOnOverlayClick={false}
            className={`onboarding-modal bg-white p-6 rounded-lg transition-all duration-700 ease-in-out ${animationClass}`}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out"
          >
            <h2 className="text-lg font-semibold mb-4">Login Required</h2>
            <p className="mb-4">
              You need to log in to proceed with the donation.
            </p>
            <button
              onClick={handleLoginRedirect}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Login Here
            </button>
          </Modal> */}
          <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            contentLabel="Login Required"
            shouldCloseOnOverlayClick={false}
            className={`onboarding-modal bg-white p-8 rounded-lg shadow-lg transition-all duration-700 ease-in-out ${animationClass}`}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-700 ease-in-out"
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none text-2xl "
              aria-label="Close modal"
            >
              &times; {/* You can use an icon here or any other symbol */}
            </button>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              <span role="img" aria-label="lock">
                🔒
              </span>{" "}
              Login Required{" "}
              <span role="img" aria-label="lock">
                🔒
              </span>
            </h2>
            <p className="mb-6 text-center text-gray-700">
              You need to log in to proceed with the donation.
            </p>
            <div className="flex justify-center">
              <button
                onClick={handleLoginRedirect}
                className="px-6  text-white p-2 rounded-sm btn-style-1 md:mt-4 mt-6 !font-semibold"
              >
                <span role="img" aria-label="login">
                  👉
                </span>{" "}
                Login Here{" "}
                <span role="img" aria-label="login">
                  👈
                </span>
              </button>
            </div>
          </Modal>
        </div>
      </div>

      {/* <div className=" p-8 flex flex-col md:flex-row items-center justify-center py-6 bg-gray-100">
        <div className="2xl:w-1/2 md:w-1/2 md:pr-8 relative">
          <img
            src="assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img  w-full h-auto"
          />
          <div className="counter-overlay  bottom-20 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        <div className="2xl:w-1/3 md:w-1/2 flex flex-col items-start md:text-left ">
          <h2 className="text-lg md:text-2xl font-semibold mb-4">
            Contribute Monthly
            <br />
            To Preserve Innumerable Lives
          </h2>
          <p className="text-black font-medium md:mb-10 mb-5 text-sm md:text-md">
            "Giving Every Month" is a monthly subscription program specifically
            crafted to support patients in obtaining critical and life-saving
            medical care promptly.
          </p>

          <button
            className="btn-style-1 py-2 text-white px-6 rounded mb-4"
            onClick={handleDonateNowClick}
          >
            Give Every Month
          </button>
          <div className="counter-with-text">
            <p className="md:text-2xl font-bold mb-2 italic">
              <span className="orange-txt ">100%</span> Transparency |
              Charitable Tax Benefits
            </p>
          </div>
        </div>
      </div> */}
      <ToastContainer theme="colored" />
      <Footer />
    </div>
  );
};

export default DonateNow;
