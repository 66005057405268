import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Triangle } from "react-loader-spinner";
import axios from "axios";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import Tooltip from "../Common/Tooltip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LiveFeeds = ({ config }) => {
  const [liveFeeds, setLiveFeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const isFetching = useRef(false); // Prevent overlapping fetches
  const sliderRef = useRef();
  const [counter, setCounter] = useState(1);

  const sliderSettingsFeeds = {
    dots: false,
    infinite: true, // Enable circular scrolling
    speed: 1000,
    slidesToShow: Math.min(3, liveFeeds.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: false,
    afterChange: handleSlideChange, // Handle slide change
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  useEffect(() => {
    if (config && config.partnerId) {
      fetchPaginatedFeeds(config.partnerId, page);
    }
  }, [config, page]);

  const fetchPaginatedFeeds = async (partnerId, page) => {
    if (isFetching.current) return; // Prevent overlapping fetches
    isFetching.current = true;
    setLoading(true);

    try {
      const response = await axios.get(
        `${TQ_URL_USPROJECT}/fetchPaginatedLiveFeeds`,
        // `${TQ_URL_USPROJECT}/getLiveFeeds`,

        
        {
          params: { partnerId, page, size: 50 }, // Fetch 50 rows per call
        }
      );

      if (response.data.status === "success") {
        const newFeeds = response.data.data;
        console.log(newFeeds);
        setLiveFeeds((prevFeeds) => [...prevFeeds, ...newFeeds]);

        if (newFeeds.length < 50) setHasMore(false); // Stop fetching if fewer than 50 rows
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching paginated feeds:", error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
      isFetching.current = false;
    }
  };

  function handleSlideChange(currentIndex) {
    // Check if the 48th row has been displayed
    if (currentIndex === liveFeeds.length - 3 && hasMore) {
      setTimeout(() => {
        setPage((prevPage) => prevPage + 1); // Fetch new data after a slight delay
      }, 500); // Ensure smooth transition
    }
  }

  const shareOnTwitter = (fundraiserId, donationType) => {
    if (donationType !== "Recurring") {
      const url = `https://twitter.com/intent/tweet?text=Check out this fundraiser!&url=${window.location.origin}/fundraiser/${fundraiserId}`;
      window.open(url, "_blank");
    } else {
      const url = `https://twitter.com/intent/tweet?text=Support ongoing fundraisers on Prabalta! Make a recurring donation to help make a difference!&url=https://prabalta.org`;

      window.open(url, "_blank");
    }
  };

  const shareOnWhatsApp = (fundraiserId, donationType) => {
    if (donationType !== "Recurring") {
      const url = `https://api.whatsapp.com/send?text=Check out this fundraiser on Prabalta! ${window.location.origin}/fundraiser/${fundraiserId}`;
      window.open(url, "_blank");
    } else {
      const url = `https://api.whatsapp.com/send?text=Support ongoing fundraisers on Prabalta! Make a recurring donation to help make a difference: https://prabalta.org`;
      window.open(url, "_blank");
    }
  };

  const shareOnFacebook = (fundraiserId, donationType) => {
    if (donationType !== "Recurring") {
      const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/fundraiser/${fundraiserId}`;
      window.open(url, "_blank");
    } else {
      const url =
        "https://www.facebook.com/sharer/sharer.php?u=https://prabalta.org";
      window.open(url, "_blank");
    }
  };

  return (
    <div className="live-feed-sec md:py-10">
      <h2 className="txt-style-7 mb-8 font-family-poppins text-center">
        Live Feeds
      </h2>
      {loading && page === 0 ? (
        <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
          <Triangle height="120" width="120" color="blue" visible={true} />
        </div>
      ) : liveFeeds.length === 0 ? (
        <p className="mx-auto text-center text-lg font-semibold mt-5">
          No Data Found
        </p>
      ) : (
        <Slider {...sliderSettingsFeeds} ref={sliderRef} className="container">
          {liveFeeds.map((donation, index) =>
            donation.donationType !== "Recurring" ? (
              <Link to={`/fundraiser/${donation.fundraiserId}`} key={index}>
                <div className="donation-box text-center w-80 h-[450px] md:w-96 md-lg:w-[20rem] md:h-[450px] mx-auto md:mx-10 mb-10 rounded-lg shadow-lg flex flex-col justify-between">
                  {donation.imageList && donation.imageList.length > 0 ? (
                    <img
                      src={donation.imageList[0]}
                      alt="Data Image"
                      className="w-full rounded-t-2xl" // Add rounded corners to the top of the card
                      style={{ height: "50%" }} // Set height to 50% of the card
                      onError={(e) => {
                        e.target.src = "/assets/images/defaultFund.jpg";
                      }}
                    />
                  ) : (
                    <img
                      src="/assets/images/defaultFund.jpg"
                      alt="Default Image"
                      className="w-full rounded-t-2xl" // Add rounded corners to the top of the card
                      style={{ height: "50%" }} // Set height to 50% of the card
                    />
                  )}
                  <div className="donation-description-box p-4 flex-grow">
                    <Tooltip
                      text={`${
                        donation.donorName
                          ? donation.donorName
                          : "Generous Anonymous Donor"
                      } has donated $${donation.donatedAmt.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )} to ${donation.title}`}
                    >
                      <p
                        className="description-text text-gray-500 mb-4 h-[5rem] overflow-hidden text-ellipsis"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {donation.donorName
                          ? donation.donorName
                          : "Generous Anonymous Donor"}{" "}
                        just donated{" "}
                        <span className="text-2xl font-bold italic">
                          {"$"}
                          {donation.donatedAmt.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>{" "}
                        {"   "}
                        to {donation.title}
                      </p>
                    </Tooltip>

                    <div className="pb-6 flex justify-center items-center space-x-3 social-icon-box">
                      {/* twitter Icon */}

                      <Tooltip text="Share on Twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 72 72"
                          viewBox="0 0 72 72"
                          className="w-8"
                          id="twitter-x"
                          onClick={() => {
                            shareOnTwitter(
                              donation.fundraiserId,
                              donation.donationType
                            );
                          }}
                        >
                          <switch>
                            <g>
                              <path
                                d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
        h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                              ></path>
                            </g>
                          </switch>
                        </svg>
                      </Tooltip>

                      <div>
                        <Tooltip text="Share on WhatsApp">
                          <svg
                            viewBox="0 0 48 48"
                            className="w-6"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            fill="#000000"
                            onClick={() => {
                              shareOnWhatsApp(
                                donation.fundraiserId,
                                donation.donationType
                              );
                            }}
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <title>Whatsapp-color</title>{" "}
                              <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                              <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                {" "}
                                <g
                                  id="Color-"
                                  transform="translate(-700.000000, -360.000000)"
                                  fill="#67C15E"
                                >
                                  {" "}
                                  <path
                                    d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                    id="Whatsapp"
                                  ></path>
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </Tooltip>
                      </div>

                      {/* Facebook Icon */}

                      <Tooltip text="Share on Facebook">
                        <svg
                          viewBox="-5 0 20 20"
                          className="w-6"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          onClick={() => {
                            shareOnFacebook(
                              donation.fundraiserId,
                              donation.donationType
                            );
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>facebook [#176]</title>{" "}
                            <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              {" "}
                              <g
                                id="Dribbble-Light-Preview"
                                transform="translate(-385.000000, -7399.000000)"
                                fill="#4267b2"
                              >
                                {" "}
                                <g
                                  id="icons"
                                  transform="translate(56.000000, 160.000000)"
                                >
                                  {" "}
                                  <path
                                    d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                    id="facebook-[#176]"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </Tooltip>
                    </div>

                    {donation.donationType !== "Recurring" && (
                      <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden mb-2">
                        <div
                          className="bg-green-500 h-full rounded-full transition-all duration-500 ease-in-out"
                          style={{
                            width: `${donation.amountAchivedInPercetage}%`,
                          }}
                        />
                      </div>
                    )}

                    {donation.donationType !== "Recurring" && (
                      <p className="donation-info-text mb-2">
                        <span className="donation-info-numbers">
                          {"$"}
                          {donation.amtAchieved.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}{" "}
                        </span>
                        Raised,{" "}
                        <span className="donation-info-numbers">
                          {donation.donorCount}{" "}
                        </span>
                        donors
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            ) : (
              <a href="https://prabalta.org" key={index} target="_blank">
                <div className="donation-box text-center w-80 h-[450px] md:w-96 md-lg:w-[20rem] md:h-[450px] mx-auto md:mx-10 mb-10 rounded-lg shadow-lg flex flex-col justify-between">
                  {donation.imageList && donation.imageList.length > 0 ? (
                    <img
                      src={donation.imageList[0]}
                      alt="Data Image"
                      className="w-full rounded-t-2xl" // Add rounded corners to the top of the card
                      style={{ height: "50%" }} // Set height to 50% of the card
                      onError={(e) => {
                        e.target.src = "/assets/images/defaultFund.jpg";
                      }}
                    />
                  ) : (
                    <img
                      src="/assets/images/defaultFund.jpg"
                      alt="Default Image"
                      className="w-full rounded-t-2xl" // Add rounded corners to the top of the card
                      style={{ height: "50%" }} // Set height to 50% of the card
                    />
                  )}
                  <div className="donation-description-box p-4 flex-grow">
                    <Tooltip
                      text={`${
                        donation.donorName
                          ? donation.donorName
                          : "Generous Anonymous Donor"
                      } just donated $${donation.donatedAmt.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )} to ${donation.causeName} as part of their ${
                        donation.freqName
                      } donation!`}
                    >
                      <p
                        className="description-text text-gray-500 mb-4 h-[5rem] overflow-hidden text-ellipsis"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {donation.donorName
                          ? donation.donorName
                          : "Generous Anonymous Donor"}{" "}
                        just donated{" "}
                        <span className="text-2xl font-bold italic">
                          {"$"}
                          {donation.donatedAmt.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>{" "}
                        to {donation.causeName} as part of their{" "}
                        {donation.freqName} donation!
                      </p>
                    </Tooltip>

                    <div className="pb-6 flex justify-center items-center space-x-3 social-icon-box">
                      {/* twitter Icon */}

                      <Tooltip text="Share on Twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 72 72"
                          viewBox="0 0 72 72"
                          className="w-8"
                          id="twitter-x"
                          onClick={() => {
                            shareOnTwitter(
                              donation.fundraiserId,
                              donation.donationType
                            );
                          }}
                        >
                          <switch>
                            <g>
                              <path
                                d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
        h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                              ></path>
                            </g>
                          </switch>
                        </svg>
                      </Tooltip>

                      <div>
                        <Tooltip text="Share on WhatsApp">
                          <svg
                            viewBox="0 0 48 48"
                            className="w-6"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            fill="#000000"
                            onClick={() => {
                              shareOnWhatsApp(
                                donation.fundraiserId,
                                donation.donationType
                              );
                            }}
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <title>Whatsapp-color</title>{" "}
                              <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                              <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                {" "}
                                <g
                                  id="Color-"
                                  transform="translate(-700.000000, -360.000000)"
                                  fill="#67C15E"
                                >
                                  {" "}
                                  <path
                                    d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                                    id="Whatsapp"
                                  ></path>
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </Tooltip>
                      </div>

                      {/* Facebook Icon */}

                      <Tooltip text="Share on Facebook">
                        <svg
                          viewBox="-5 0 20 20"
                          className="w-6"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          onClick={() => {
                            shareOnFacebook(
                              donation.fundraiserId,
                              donation.donationType
                            );
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>facebook [#176]</title>{" "}
                            <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              {" "}
                              <g
                                id="Dribbble-Light-Preview"
                                transform="translate(-385.000000, -7399.000000)"
                                fill="#4267b2"
                              >
                                {" "}
                                <g
                                  id="icons"
                                  transform="translate(56.000000, 160.000000)"
                                >
                                  {" "}
                                  <path
                                    d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                    id="facebook-[#176]"
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </Tooltip>
                    </div>

                    {donation.donationType !== "Recurring" && (
                      <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden mb-2">
                        <div
                          className="bg-green-500 h-full rounded-full transition-all duration-500 ease-in-out"
                          style={{
                            width: `${donation.amountAchivedInPercetage}%`,
                          }}
                        />
                      </div>
                    )}

                    {donation.donationType !== "Recurring" && (
                      <p className="donation-info-text mb-2">
                        <span className="donation-info-numbers">
                          {"$"}
                          {donation.amtAchieved.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}{" "}
                        </span>
                        Raised,{" "}
                        <span className="donation-info-numbers">
                          {donation.donorCount}{" "}
                        </span>
                        donors
                      </p>
                    )}
                  </div>
                </div>
              </a>
            )
          )}
        </Slider>
      )}
    </div>
  );
};

export default LiveFeeds;
