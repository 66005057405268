import React, { useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DatePickerInput } from "@mantine/dates";
import Slider from "react-slick";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import Modal from "react-modal";
import "./selfadmin.css";

const schema = yup.object().shape({
  document: yup.mixed().required("Document is Required *"),
  description: yup.string().required("Description is Required *"),
  title: yup.string().required("Title is required"),
  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty"),
  targetDate: yup.date("Invalid date").required("Target date is required"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

const SelfAdmin = () => {
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [causeList, setCauseList] = useState(false);
  const [isImageHover, setIsImageHover] = useState(false);
  const { state } = useLocation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(null); // New state for payment method
  const [errorMessage, setErrorMessage] = useState(null); // State for error message
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [bankDetails, setBankDetails] = useState([]); // Bank details state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [selectedBankIndex, setSelectedBankIndex] = useState(null); // To manage the selected bank radio button
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  // State to manage the loading of default bank details
  const [isLoadingDefaultBank, setIsLoadingDefaultBank] = useState(false);

  // State to store the default bank account details
  const [defaultBankDetails, setDefaultBankDetails] = useState(null);

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    fetchDefaultBankDetails();
    const value1 = state?.selectedCauses || "defaultValue1";
    setCauseList(value1);
    console.log("selected causes:", value1);
  }, [state]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleFileChange = (event) => {
  //   const files = event.target.files;

  //   if (files.length > 0) {
  //     const newFiles = Array.from(files);
  //     const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

  //     setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //     setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

  //     newFiles.forEach((file) => {
  //       console.log("File added:", file.name);
  //     });
  //   }
  // };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (files.length > 0) {
      const newFiles = Array.from(files);
      const validFiles = newFiles.filter((file) => {
        if (file.size > maxSize) {
          toast.error(
            `File "${file.name}" is too large. Maximum size allowed is 5 MB.`
          );
          return false;
        }
        return true;
      });

      const newPreviews = validFiles.map((file) => URL.createObjectURL(file));

      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      validFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    setFilePreviews((prevPreviews) => {
      const newPreviews = [...prevPreviews];
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const newFiles = Array.from(files);
      const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      newFiles.forEach((file) => {
        console.log("File added:", file.name);
      });
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    };
    return new Intl.NumberFormat("en-US", options).format(value);
  };

  const handleFundraiserGoalChange = (e, onChange) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value === "") {
      onChange(null);
      return;
    }
    value = parseInt(value, 10);
    onChange(value);
  };

  const onSubmitHandler = async (data, event) => {
    console.log("Submit Clicked", data);

    // Partner ID check
    const partnerId = config.partnerId;
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }

    // Prevent form submission if no default payment method is set
    // if (!defaultBankDetails) {
    //   setErrorMessage("Please add a bank account before submitting.");
    //   toast.error("Please add a bank account before submitting.");
    //   return; // Stop the form submission here
    // }

    // Prevent form submission if no files are uploaded
    if (uploadedFiles.length === 0) {
      toast.error("Please select a document to upload.");
      return;
    }

    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    const targetDate = new Date(data.targetDate);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const selectedDate = targetDate.toLocaleDateString("en-IN", options);

    // Append necessary form data
    formData.append("fundraiserName", "");
    formData.append("partnerId", partnerId);
    formData.append("relationId", 1);
    formData.append("otherInput", "");
    formData.append("address", "");
    formData.append("zipcode", "");
    formData.append("state", "");
    formData.append("country", "");
    formData.append("desc", data.description);
    // formData.append("causeTypesIds", causeList);
    formData.append("resourceType", 1);
    formData.append("title", data.title);
    formData.append("currencyType", "USD");
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));
    formData.append("status", 1);
    formData.append("targetDate", selectedDate);
    formData.append("fundraiserType", "Own");

    // Add the default bank account's method ID to the form data
    formData.append("paymentMethodId", defaultBankDetails ? (defaultBankDetails.methodId || defaultBankDetails.id) : 1);


    // Append uploaded files to the form data
    uploadedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("ssnNo", "");

    const token = localStorage.getItem("accessToken");

    // Make API request to save fundraiser details
    axios
      .post(`${TQ_URL}/saveFundraiserDetails`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.Msg === "Fundraiser Details Saved Successfully") {
          successFormSubmit();
        }

        // Navigate to all-fundraisers page after a short delay
        setTimeout(() => {
          navigate(`/all-fundraiser`);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === "Failed to upload files..") {
          toast.error("Failed to upload the image. Please try again.");
        } else {
          toast.error("Something went wrong.");
        }
        console.error("Error uploading file:", error);
      });
  };

  // Function to fetch the most recent payment method
  const fetchRecentPaymentMethod = async (clientId) => {
    try {
      const stripeAccountId = localStorage.getItem("stripeAccountId"); // Fetch from localStorage
      const isDefaultAccount = localStorage.getItem("isDefaultAccount"); // Fetch isDefaultAccount flag from localStorage

      if (!stripeAccountId) {
        console.error("Stripe account ID is missing.");
        return;
      }

      const url = `${TQ_URL}/recent-payment-method`;
      const requestData = {
        clientId,
        accountId: stripeAccountId,
        isDefault: isDefaultAccount === "true", // Convert to boolean and send even if false
      };

      const response = await getRequestWithTokenWithDataWithAuth(
        url,
        requestData
      );

      if (response && response.paymentMethod) {
        setPaymentMethod(response.paymentMethod); // Store payment method details
        setErrorMessage(null); // Clear any previous errors
      } else {
        setErrorMessage("No payment method found, please add a bank account.");
      }

      // Remove the account ID and isDefaultAccount from localStorage after use
      localStorage.removeItem("stripeAccountId");
      localStorage.removeItem("isDefaultAccount");
      fetchDefaultBankDetails();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage("No payment method added, please try again.");
      } else {
        setErrorMessage(
          "Something went wrong while fetching the payment method."
        );
      }
    }
  };

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  const openSliderModal = () => {
    setShowSliderModal(true);
  };

  const closeSliderModal = () => {
    setShowSliderModal(false);
  };

  const getAddBankAccount = async () => {
    try {
      const partnerDataString = localStorage.getItem("partnerData");
      const partnerJson = JSON.parse(partnerDataString);
      const partnerJsonId = partnerJson.partnerId;

      const response = await axios.post(`${TQ_URL}/setup-payment`, {
        clientId: partnerJsonId,
      });

      if (response.data.url) {
        const accountId = extractAccountIdFromUrl(response.data.url); // Extract account ID
        if (accountId) {
          localStorage.setItem("stripeAccountId", accountId); // Store accountId in localStorage
        } else {
          console.error("Failed to extract Stripe account ID from URL.");
          toast.error("Failed to extract Stripe account ID from the URL.");
          return;
        }

        const popup = window.open(
          response.data.url,
          "stripePopup",
          "width=600,height=700"
        );

        const timer = setInterval(async () => {
          if (popup.closed) {
            clearInterval(timer);

            // Fetch the recent payment method after popup closes
            await fetchRecentPaymentMethod(partnerJsonId);
          }
        }, 500); // Check every 500ms
      } else {
        toast.error("Failed to initiate bank account setup.");
      }
    } catch (error) {
      toast.error("Something went wrong while setting up the bank account.");
    }
  };

  // Helper function to extract account ID from the URL
  const extractAccountIdFromUrl = (url) => {
    const regex = /(acct_[a-zA-Z0-9]+\/[a-zA-Z0-9]+)$/;
    const match = url.match(regex);
    return match ? match[0] : null;
  };

  const fetchBankDetails = async () => {
    setIsLoadingModal(true);
    try {
      const partnerId = partnerData.partnerId;
      const response = await axios.get(
        `${TQ_URL}/fetch-bank-details/${partnerId}`
      );
      const bankData = response.data;

      // Store the bank details along with the methodId
      setBankDetails(bankData);

      // Find the default bank and set the default selectedBankIndex
      const defaultBankIndex = bankData.findIndex((bank) => bank.isDefault);
      setSelectedBankIndex(defaultBankIndex);
    } catch (error) {
      toast.error("Failed to fetch bank details.");
      console.error("Error fetching bank details:", error);
    } finally {
      setIsLoadingModal(false);
    }
  };

  const openModal = () => {
    setBankDetails([]); // Clear previous bank details
    setSelectedBankIndex(null);
    setIsModalOpen(true);
    fetchBankDetails(); // Fetch fresh data
  };

  const closeModal = () => {
    fetchDefaultBankDetails();
    setIsModalOpen(false);
  };

  const fetchDefaultBankDetails = async () => {
    try {
      setIsLoadingDefaultBank(true);
      const response = await axios.get(
        `${TQ_URL}/fetch-default-bank/${partnerData.partnerId}`
      );

      if (response.status === 200 && response.data) {
        setDefaultBankDetails(response.data); // Set the default bank details to state
      } else {
        toast.error("No default bank account found.");
      }
    } catch (error) {
      // console.error("Error fetching default bank details:", error);
      // toast.error("Failed to fetch default bank details.");
    } finally {
      setIsLoadingDefaultBank(false);
    }
  };

  const handleDefaultChange = async (selectedIndex) => {
    setSelectedBankIndex(selectedIndex);

    const selectedBank = bankDetails[selectedIndex];
    const methodId = selectedBank.methodId || selectedBank.id; // Ensure you're getting the correct methodId

    if (methodId) {
      try {
        const partnerId = partnerData.partnerId;
        const response = await axios.put(
          `${TQ_URL}/update-default?methodId=${methodId}&partnerId=${partnerId}`
        );

        if (response.status === 200) {
          toast.success(
            response.data.message ||
            "Default bank account updated successfully."
          );

          // After successful update, manually update the bankDetails state to mark the selected bank as default
          const updatedBankDetails = bankDetails.map((bank, index) => ({
            ...bank,
            isDefault: index === selectedIndex, // Only mark the selected bank as default
          }));

          setBankDetails(updatedBankDetails); // Update state with the new bank details
        } else {
          toast.error(
            response.data.error || "Failed to update the default bank account"
          );
        }
      } catch (error) {
        console.error("Error updating default bank account:", error);
        toast.error("Error updating default bank account");
      }
    } else {
      console.error("methodId is undefined or missing");
    }
  };

  const defaultBank = bankDetails.find((bank) => bank.isDefault);

  // Function to "remove" the account and re-enable the Add Bank Details button
  const handleRemoveAccount = () => {
    // Reset the paymentMethod state, which will hide the account info and show the Add Bank button
    setPaymentMethod(null);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <DonorManagementTop />
      <ProgressBar value={50} size="sm" color="blue" />

      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen w-full">
          <Triangle
            height="120"
            width="120"
            color="blue"
            ariaLabel="triangle-loading"
            visible={true}
          />
          <p className="text-blue-500 mt-4">
            Please wait, we are creating your fundraiser
          </p>
        </div>
      ) : (
        <div>
          <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-10">
            Add more details for organisation / Individual
          </h2>

          <div className="md:pb-20 md:flex justify-between ">
            <div className="2xl:w-1/4 lg:w-1/3 md:me-20 mx-10 md:mx-0 md:block hidden hide-md-lg">
              <img src="assets/images/information.jpg" className="md:w-full" />
            </div>

            {/* Form container */}
            <div className="md:w-2/3 m-4 md:m-0 w-full-md-lg lg:ml-10 leftMarg">
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20"
              >
                {/* Upload Document */}
                <div className="md:flex items-center">
                  <label>Upload Documents</label>
                  <div
                    className="flex justify-between w-full items-center my-5 md:my-0"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      className="mb-6 relative flex flex-col w-full"
                      style={{
                        borderColor: isDragOver ? "blue" : "gray",
                        boxShadow: isDragOver
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                        borderRadius: "8px",
                        borderStyle: "dashed",
                        borderWidth: "2px",
                        paddingInline: "20px",
                        paddingBlock: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {/* File Upload and Preview */}
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col w-full">
                          {uploadedFiles.map((file, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center mb-2"
                            >
                              <p className="mr-2">{file.name}</p>
                              <button
                                type="button"
                                className="text-red-500 hover:text-red-700 cursor-pointer"
                                onClick={() => removeFile(index)}
                              >
                                &#x2715;
                              </button>
                            </div>
                          ))}
                        </div>
                        {uploadedFiles.length > 0 && (
                          <button
                            type="button"
                            onClick={openSliderModal}
                            className="text-blue-500 underline ml-2"
                          >
                            Show Preview
                          </button>
                        )}
                      </div>

                      <label
                        htmlFor="documentInput"
                        className="cursor-pointer mt-4"
                      >
                        <img
                          src="assets/images/cloud-icon.svg"
                          className="mx-auto"
                          alt="Cloud Icon"
                        />
                        <p className="text-xs text-center">
                          <span className="font-bold underline">
                            Click to upload
                          </span>
                          <br />
                          Doc, PNG, JPG, JPEG, MP4, PDF (Max. 800 x 400, Size up
                          to 2 MB)
                        </p>
                      </label>
                      <input
                        {...register("document")}
                        type="file"
                        accept=".jpg, .jpeg, .png, .doc, .pdf, .mp4"
                        onChange={handleFileChange}
                        className="hidden"
                        id="documentInput"
                        multiple
                      />
                    </div>
                  </div>
                </div>

                {/* Title */}
                <div className="mb-6 relative md:flex">
                  <label>Title</label>
                  <div className="w-full">
                    <input
                      {...register("title")}
                      placeholder="title"
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.title && "border-red-500"
                        }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.title?.message}
                    </p>
                  </div>
                </div>

                {/* Description */}
                <div className="mb-6 relative md:flex">
                  <label>Description</label>
                  <div className="w-full">
                    <textarea
                      {...register("description")}
                      rows={3}
                      style={{ resize: "none" }}
                      placeholder="Description"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.description && "border-red-500"
                        }`}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.description?.message}
                    </p>
                  </div>
                </div>

                {/* Fundraiser Goal */}
                <div className="mb-6 relative md:flex">
                  <label>Fundraiser Goal</label>
                  <div className="w-full relative">
                    <Controller
                      name="fundraiserGoal"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            placeholder="Fundraiser Goal"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errors.fundraiserGoal && "border-red-500"
                              }`}
                            value={
                              field.value ? formatCurrency(field.value) : ""
                            }
                            onChange={(e) =>
                              handleFundraiserGoalChange(e, field.onChange)
                            }
                          />
                        </>
                      )}
                    />
                    <p className="text-red-500 text-sm mt-1">
                      {errors.fundraiserGoal?.message}
                    </p>
                  </div>
                </div>

                {/* Target Date */}
                <div className="mb-6 md:flex">
                  <label>Target Date</label>
                  <div className="w-full">
                    <Controller
                      name="targetDate"
                      control={control}
                      render={({ field }) => (
                        <>
                          <DatePickerInput
                            placeholder="Pick date"
                            {...field}
                            required
                            className={`bg-gray-50  border-gray-300 text-gray-900 text-sm focus:border-blue-500 ${errors.targetDate && "border-red-500"
                              }`}
                            onChange={(value) => {
                              const dateValue =
                                value instanceof Date
                                  ? value
                                  : typeof value === "string"
                                    ? new Date(value)
                                    : null;

                              field.onChange(dateValue);
                            }}
                            minDate={new Date()}
                          />
                        </>
                      )}
                    />
                    {errors.targetDate && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.targetDate.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Add Bank Details or Display Existing Account */}
                <div className="mb-6 relative md:flex items-center space-x-4">
                  <label className="block w-full md:w-1/3">
                    Link Bank Account
                  </label>

                  <div className="flex space-x-4 w-full">
                    {/* Link Bank Account Card */}
                    <div className="flex-1">
                      {errorMessage ? (
                        <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-4 w-full">
                          <div className="text-red-600">
                            <h3 className="text-lg font-semibold">
                              Bank Account Error
                            </h3>
                            <p className="text-sm mt-2">{errorMessage}</p>
                            <p className="text-sm">
                              Please add a bank account for donations to
                              proceed.
                            </p>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              onClick={getAddBankAccount}
                              className="text-blue-500 underline cursor-pointer"
                            >
                              Add Bank Account
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full"
                          onClick={getAddBankAccount}
                        >
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="h-8 w-8 text-blue-600"
                                fill="currentColor"
                              >
                                <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                              </svg>
                            </div>
                            <div className="ml-4">
                              <h3 className="text-lg font-medium text-gray-900">
                                Link Bank Account
                              </h3>
                              <p className="text-sm text-gray-500">
                                Click to add a bank account for donations.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* View Bank Details Card */}
                    {/* <div className="flex-1">
                    <div
                      className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full"
                      onClick={openModal}
                    >
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="h-8 w-8 text-blue-600"
                            fill="currentColor"
                          >
                            <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                          </svg>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-lg font-medium text-gray-900">View Bank Details</h3>
                          <p className="text-sm text-gray-500">Click to view your bank account details.</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="mb-6 relative md:flex items-center space-x-4">
                  <label className="w-full md:w-1/3">Default Bank</label>

                  <div className="w-full">
                    {isLoadingDefaultBank ? (
                      <div className="flex justify-center">
                        <Triangle
                          height="40"
                          width="40"
                          color="blue"
                          ariaLabel="loading"
                        />
                      </div>
                    ) : defaultBankDetails ? (
                      <div className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300 w-full flex items-center justify-between">
                        <div className="flex items-center">
                          {/* SVG Icon for Default Bank */}
                          <div className="flex-shrink-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="h-10 w-10"
                            >
                              <path
                                fill="#0fbd4c"
                                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                              />
                            </svg>
                          </div>

                          <div className="ml-4">
                            {/* Bank Account Details */}
                            <p className="text-sm mt-2">
                              <span className="font-bold">Bank Name:</span>{" "}
                              {defaultBankDetails.bankName}
                            </p>
                            <p className="text-sm mt-2">
                              <span className="font-bold">Account Number:</span>{" "}
                              XXXXXX
                              {defaultBankDetails.last4Digits}
                            </p>
                          </div>
                        </div>

                        {/* Button for Other Connected Accounts */}
                        <div className="ml-auto">
                          <button
                            type="button" // Ensure this is a button, not a submit input
                            onClick={openModal} // Open the modal only when clicked
                            className="bg-indigo-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transform hover:scale-105 transition-transform duration-300 ease-in-out"
                          >
                            Other Connected Accounts
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p className="text-red-500">
                        No default bank account found.
                      </p>
                    )}
                  </div>
                </div>

                {/* Terms and Conditions Checkbox */}
                <div className="mb-6 md:flex justify-between">
                  <div></div>
                  <div className="md:w-4/6">
                    <input
                      {...register("termsAndConditions")}
                      type="checkbox"
                      className={`mr-2`}
                    />
                    <label className="text-dark-200">
                      Clicking implies agreement with our{" "}
                      <a onClick={() => setShowTermsPopup(true)}>
                        {" "}
                        <u>Terms and Conditions</u>
                      </a>
                    </label>
                    <p className="text-red-500 text-sm mt-1">
                      {errors.termsAndConditions?.message}
                    </p>
                    <div className="flex w-full md:justify-between items-center justify-center">
                      {/* Submit Button */}
                      {/* <button
                        type="submit"
                        className={`md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 ${
                          defaultBankDetails
                            ? "bg-blue-500"
                            : "bg-gray-400 cursor-not-allowed"
                        }`}
                        disabled={!defaultBankDetails} // Disable the button if there is no default bank account
                      >
                        Submit
                      </button> */}

                      {/* Submit Button */}
                      <button
                        type="submit"
                        className={`md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 bg-blue-500`}
                        disabled={false} // Always enable the button
                      >
                        Submit
                      </button>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal for displaying images in a slider */}
      {showSliderModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md max-w-lg w-full h-auto relative">
            <button
              onClick={closeSliderModal}
              className="absolute top-2 right-2 text-red-500 text-2xl font-bold"
            >
              &times;
            </button>
            <Slider {...sliderSettings}>
              {filePreviews.map((preview, index) => (
                <div key={index} className="flex justify-center items-center">
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                    className="w-full max-h-96 object-contain"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false} // Prevents closing on outside clicks
        contentLabel="Bank Details"
        className="bank_modal p-6 fixed-size-modal border-none outline-none shadow-lg rounded-lg"
        overlayClassName="bank_modal-overlay"
      >
        {/* Modal Header */}
        <div className="bank_modal-header flex justify-between items-center pb-4">
          <h2 className="bank_modal-title text-xl font-bold">Bank Details</h2>
          {/* Close button */}
          <button
            onClick={closeModal}
            className="bank_modal-close text-red-500 hover:text-red-700 font-bold text-2xl transform transition-transform duration-200 hover:scale-125"
          >
            &times;
          </button>
        </div>

        {/* Modal Body */}
        <div className="bank_modal-body space-y-4">
          {isLoadingModal ? (
            // Show the loading spinner centered within the modal
            <div
              className="flex flex-col justify-center items-center h-full"
              style={{ height: "300px" }}
            >
              <Triangle
                height="80"
                width="80"
                color="blue"
                ariaLabel="loading"
              />
              <p className="text-gray-600 mt-4">
                Please wait, we are loading your bank accounts...
              </p>
            </div>
          ) : (
            <>
              {/* Default Account Section */}
              {defaultBank && (
                <div className="default-account-section">
                  <h3 className="text-lg font-bold mb-2">Default Account</h3>
                  <div className="flex items-center p-4 rounded-lg shadow-lg bg-blue-100">
                    <div className="flex-shrink-0">
                      {/* Bank SVG */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="h-8 w-8 text-blue-600"
                        fill="currentColor"
                      >
                        <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                      </svg>
                    </div>

                    <div className="ml-6 flex-grow">
                      <p className="text-sm mt-2">
                        <span className="font-bold">Bank Name:</span>{" "}
                        {defaultBank.bankName}
                      </p>
                      <p className="text-sm">
                        <span className="font-bold">Account Number:</span>{" "}
                        {"XXXXXX" + defaultBank.last4Digits}
                      </p>
                    </div>

                    <div className="flex-shrink-0 ml-4">
                      <span className="px-3 py-1 bg-green-500 text-white text-xs rounded-full">
                        Default
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* Other Accounts Section */}
              {bankDetails.length > 0 && (
                <div className="other-accounts-section">
                  <h3 className="text-lg font-bold mb-2">Other Accounts</h3>
                  <div className="space-y-4">
                    {bankDetails.map((bank, index) => (
                      <div
                        key={index}
                        className={`flex items-center p-4 rounded-lg shadow-lg ${bank.isDefault ? "bg-blue-100" : "bg-blue-50"
                          }`}
                      >
                        {/* Radio Button */}
                        <div className="flex-shrink-0">
                          <input
                            type="radio"
                            name="defaultBank"
                            checked={selectedBankIndex === index}
                            onChange={() => handleDefaultChange(index)} // Call handleDefaultChange when a bank is selected
                            className="form-radio h-5 w-5 text-blue-600"
                          />
                        </div>

                        {/* Bank Information */}
                        <div className="ml-6 flex-grow">
                          <p className="text-sm mt-2">
                            <span className="font-bold">Bank Name:</span>{" "}
                            {bank.bankName}
                          </p>
                          <p className="text-sm">
                            <span className="font-bold">Account Number:</span>{" "}
                            {"XXXXXX" + bank.last4Digits}
                          </p>
                        </div>

                        {/* Default Mark */}
                        <div className="flex-shrink-0 ml-4">
                          {bank.isDefault || selectedBankIndex === index ? (
                            <span className="px-3 py-1 bg-green-500 text-white text-xs rounded-full">
                              Default
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
      {/* <Footer /> */}
    </>
  );
};

export default SelfAdmin;
