import { useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Box, Button } from "@mantine/core";
import Swal from "sweetalert2";
import TopHeader from "../Home/TopHeader";
import HistorySidebar from "../Home/HistorySidebar";
import {
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { TQ_URL_USPROJECT } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReceiptPopup from "../Common/ReceiptPopup";

const formatCurrency = (value) => {
  if (!value) return "";
  const amount = parseFloat(value);
  if (isNaN(amount)) return "";
  return `$${amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const columns = [
  {
    accessorKey: "id",
    header: "ID",
    enableSorting: false,
    enableColumnFilter: false,
    render: (row) => row.index + 1,
  },
  {
    accessorKey: "causeNames",
    header: "Fundraiser Title",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "donatedAmt",
    header: "Amount Donated",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => <Box>{formatCurrency(cell.getValue())}</Box>,
  },
  {
    accessorKey: "freqName",
    header: "Frequency",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "statusName",
    header: "Status",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row, cell }) => {
      const status =
        row.original.freqName === "One-Time" && cell.getValue() === "Active"
          ? "Success"
          : cell.getValue();
      return (
        <Box
          sx={(theme) => ({
            backgroundColor:
              status === "Active" || status === "Success"
                ? "#DAF4EB"
                : status === "Stop" || status === "Failed"
                ? "#FDE4E4"
                : status === "Pending"
                ? "#FFE0A1"
                : "inherit",
            color:
              status === "Active" || status === "Success"
                ? theme.colors.green[9]
                : status === "Stop" || status === "Failed"
                ? "#FF0000"
                : status === "Pending"
                ? "#FFA200"
                : "inherit",
            borderRadius: "4px",
            padding: "4px",
            width: "80px",
            textAlign: "center",
          })}
        >
          {status}
        </Box>
      );
    },
  },
];

const RecurringDonation = () => {
  const [data, setData] = useState([]);
  const [showReceiptPopup, setShowReceiptPopup] = useState(false);
  const [receiptPopupData, setReceiptPopupData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getRecurringDonationHistory = async () => {
    try {
      const url = new URL(
        `${TQ_URL_USPROJECT}/getAllRecurringDonationListByLoggedInUser`
      );

      console.log(url.href);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);

      const response = await getRequestWithTokenWithoutData(url.toString());
      console.log(response);

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error(
        "Error in fetching the recurring Donation's history:",
        error
      );
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleStopRecurringDonation = (rowId, statusId) => {
    console.log(rowId);
    console.log(statusId);

    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the status for your fundraiser",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        handleStopRecurringDonationProcess(rowId, statusId);
      }
    });
  };

  const handleStopRecurringDonationProcess = async (rowId, status) => {
    try {
      let request = {
        recurringDonationId: rowId,
        status: status,
      };
      const response = await patchRequestWithTokenWithData(
        `${TQ_URL_USPROJECT}/stopRecurringDonation`,
        request
      );
      console.log(response);

      if (status === 4) {
        const updatedData = data.map((item) => {
          if (item.recurringMoneyDonationId === rowId) {
            return { ...item, statusName: "Stop" };
          }
          return item;
        });

        setData(updatedData);
      }

      console.log(data);

      successRecurringDonationStopped(status);
    } catch (error) {
      console.error("Error stopping fundraising:", error);
    }
  };

  const successRecurringDonationStopped = (status) => {
    if (status === 4) toast.success("Recurring Donation Stopped Successfully!");
    else toast.success("Recurring Donation Deleted Successfully!");
  };

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getRecurringDonationHistory();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: "last",
    initialState: { showColumnFilters: false },
    rowCount,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        {/* Check if the frequency is not "One-Time" */}
        {row.original.freqName !== "One-Time" &&
          row.original.statusName !== "Stop" &&
          row.original.statusName !== "Failed" && (
            <Button
              onClick={() => {
                handleStopRecurringDonation(
                  row.original.recurringMoneyDonationId,
                  4
                );
              }}
              variant="outline"
            >
              Stop
            </Button>
          )}
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <HistorySidebar />
        </div>
        <main className="w-full verify-user1">
          <div className=" mx-auto shadow-md">
            <div className="flex flex-col lg:flex-row justify-between items-center container px-10 py-4">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Recurring Donation</h2>
              </div>
            </div>
          </div>
          <div className="px-4 md:mx-8 md:py-8 py-6">
            <MantineReactTable table={table} />
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default RecurringDonation;
